import React from 'react'
import './Footer.css'

export default () => {
  return (
    <div className='footer'>
      <p className='footText'>Thanks For Stopping By.</p>
      <p className='footText'>Have a Great Day!</p>
    </div>
  )
}