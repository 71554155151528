import React from 'react'
import './Intro.css'

export default () => {
  return (
    <div className='homepageImage'>
      <div className="headerContent">
        <h1 className='samHeader'>Sam Smiley</h1>
      </div>
    </div>
  )
}